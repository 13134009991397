import React, { useState } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';
import NeighborhoodLayer from './NeighborhoodLayer';
import ReactMapGL, { MapContext, NavigationControl, ScaleControl } from 'react-map-gl';

const DEFAULT_VIEWPORT = {
  latitude: 18.360,
  longitude: -66.0583,
  zoom: 10,
  bearing: 0,
};

export default function NeighborhoodMap() {

  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);

  return (
    <ReactMapGL
      {...viewport}
      width='100vw'
      height='100vh'
      mapStyle={'mapbox://styles/urbital/cktlnwg7g2cxt17moumy690nr'}
      onViewportChange={setViewport}
      attributionControl={true}
    >
      <NavigationControl style={{ left: '10px', top: '10px' }} />
      <ScaleControl style={{ right: '10px', bottom: '25px' }} />
      <NeighborhoodLayer />
      <CurrentZoomLevel />
    </ReactMapGL>
  );

}

function CurrentZoomLevel() {
  const context = React.useContext(MapContext);
  return <div>Zoom: {context.viewport.zoom}</div>;
}


