import './App.css';
import NeighborhoodMap from "./components/NeighborhoodMap";

function App() {
  return (
    <div className="App">
      <NeighborhoodMap/>
    </div>
  );
}

export default App;
