import React from 'react';
import { Layer, Source } from 'react-map-gl';

export default function NeighborhoodLayer() {

  return (
    <>

      <Source
        id='neighborhood-centroids-tiles'
        type='vector'
        tiles={[process.env.REACT_APP_NEIGHBORHOODS_TILE_URL]}
        maxzoom={8}
      />

      <Layer
        id='neighborhood-label'
        type='symbol'
        source='neighborhood-centroids-tiles'
        source-layer='neighborhoodcentroids'
        minzoom={14}
        paint={{
          'text-color': 'rgb(30, 30, 30)',
          'text-halo-color': 'rgb(242,243,240)',
          'text-halo-width': 1,
          'text-halo-blur': 1,
        }}
        layout={{
          'text-field': '{ward_name}',
          'text-anchor': 'bottom-left',
          'text-size': 12,
          'text-font': ['DIN Pro Regular', 'Open Sans Regular','Arial Unicode MS Regular'],
          'text-transform': 'uppercase',
        }}
      />

    </>
  );


}